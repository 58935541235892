<template>
    <b-card-code title="Mobile Banking Information">
        <validation-observer ref="simpleRules">

            <b-form class="mt-1">
                <!-- Form: bkash Info Form -->
                <div class="d-flex mt-2">
                    <feather-icon icon="BriefcaseIcon" size="19"/>
                    <h4 class="mb-0 ml-50">
                        bKash
                    </h4>
                </div>
                <b-row class="mt-1">
                    <!-- Field: Company name -->
                    <b-col cols="12" lg="4" md="6">
                        <b-form-group label="Account Number" label-for="bkash_account_number">
                            <b-form-input id="bkash_account_number" v-model="mbakingData.bkash.account_number"
                                          placeholder="Enter bkash Name"/>
                        </b-form-group>
                    </b-col>
                    <!-- Field: Company Mobile -->
                    <b-col cols="12" lg="4" md="6">
                        <b-form-group label="Account Type" label-for="bkash_account_type">
                            <b-form-input id="bkash_account_type" v-model="mbakingData.bkash.account_type"
                                          placeholder="Enter bkash account type (ex.personal,agent,merchant)"/>
                        </b-form-group>
                    </b-col>
                    <!-- Field: Company Email -->
                    <b-col cols="12" lg="4" md="6">
                        <b-form-group
                            label="Status"
                            label-for="status">
                            <b-form-select
                                v-model="mbakingData.bkash.status"
                                :options="status"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>

                <!-- Header: Rocket Info -->
                <div class="d-flex mt-2">
                    <feather-icon icon="BriefcaseIcon" size="19"/>
                    <h4 class="mb-0 ml-50">
                        Rocket
                    </h4>
                </div>
                <b-row class="mt-1">
                    <!-- Field: Company name -->
                    <b-col cols="12" lg="4" md="6">
                        <b-form-group label="Account Number" label-for="rocket_account_number">
                            <b-form-input id="rocket_account_number" v-model="mbakingData.rocket.account_number"
                                          placeholder="Enter Rocket Name"/>
                        </b-form-group>
                    </b-col>
                    <!-- Field: Company Mobile -->
                    <b-col cols="12" lg="4" md="6">
                        <b-form-group label="Account Type" label-for="rocket_account_type">
                            <b-form-input id="rocket_account_type" v-model="mbakingData.rocket.account_type"
                                          placeholder="Enter Rocket account type (ex.personal,agent,merchant)"/>
                        </b-form-group>
                    </b-col>
                    <!-- Field: Company Email -->
                    <b-col cols="12" lg="4" md="6">
                        <b-form-group
                            label="Status"
                            label-for="status">
                            <b-form-select
                                v-model="mbakingData.rocket.status"
                                :options="status"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>

                <!-- Header: Nagad Info -->
                <div class="d-flex mt-2">
                    <feather-icon icon="BriefcaseIcon" size="19"/>
                    <h4 class="mb-0 ml-50">
                        Nagad
                    </h4>
                </div>
                <b-row class="mt-1">
                    <!-- Field: Company name -->
                    <b-col cols="12" lg="4" md="6">
                        <b-form-group label="Account Number" label-for="nagad_account_number">
                            <b-form-input id="nagad_account_number" v-model="mbakingData.nagad.account_number"
                                          placeholder="Enter Nagad Name"/>
                        </b-form-group>
                    </b-col>
                    <!-- Field: Company Mobile -->
                    <b-col cols="12" lg="4" md="6">
                        <b-form-group label="Account Type" label-for="nagad_account_type">
                            <b-form-input id="nagad_account_type" v-model="mbakingData.nagad.account_type"
                                          placeholder="Enter Nagad account type (ex.personal,agent,merchant)"/>
                        </b-form-group>
                    </b-col>
                    <!-- Field: Company Email -->
                    <b-col cols="12" lg="4" md="6">
                        <b-form-group
                            label="Status"
                            label-for="status">
                            <b-form-select
                                v-model="mbakingData.nagad.status"
                                :options="status"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>

                <!-- Header: upay Info -->
                <div class="d-flex mt-2">
                    <feather-icon icon="BriefcaseIcon" size="19"/>
                    <h4 class="mb-0 ml-50">
                        Upay
                    </h4>
                </div>
                <b-row class="mt-1">
                    <!-- Field: Company name -->
                    <b-col cols="12" lg="4" md="6">
                        <b-form-group label="Account Number" label-for="upay_account_number">
                            <b-form-input id="upay_account_number" v-model="mbakingData.upay.account_number"
                                          placeholder="Enter Upay Name"/>
                        </b-form-group>
                    </b-col>
                    <!-- Field: Company Mobile -->
                    <b-col cols="12" lg="4" md="6">
                        <b-form-group label="Account Type" label-for="upay_account_type">
                            <b-form-input id="upay_account_type" v-model="mbakingData.upay.account_type"
                                          placeholder="Enter Upay account type (ex.personal,agent,merchant)"/>
                        </b-form-group>
                    </b-col>
                    <!-- Field: Company Email -->
                    <b-col cols="12" lg="4" md="6">
                        <b-form-group
                            label="Status"
                            label-for="status">
                            <b-form-select
                                v-model="mbakingData.upay.status"
                                :options="status"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row class="mt-2">
                    <b-col>
                        <b-button
                            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                            variant="primary"
                            @click.prevent="validationForm"
                        >
                            Save Changes
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card-code>
</template>
<script>
import {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BFormSelect
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import {MOBILE_BAKING_INFO} from "@core/services/api";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import useJwt from "@/auth/jwt/useJwt";
import Mixin from "@core/services/mixin";
import {checkRes} from "@core/services/helper";
import $ from "jquery";

export default {
    mixins: [
        Mixin
    ],
    components: {
        BFormSelect,
        BCardCode,
        BTab,
        BTabs,
        BCard,
        BAlert,
        BLink,
        BRow,
        BCol,
        BForm,
        BFormGroup,
        flatPickr,
        BFormInput,
        vSelect,
        BFormRadioGroup,
        BFormCheckboxGroup,
        BButton,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            mbakingData: {
               bkash:{
                   account_number:'',
                   account_type:'',
                   status:''
               },
                rocket:{
                    account_number:'',
                    account_type:'',
                    status:''
                },
                nagad:{
                    account_number:'',
                    account_type:'',
                    status:''
                },
                upay:{
                    account_number:'',
                    account_type:'',
                    status:''
                }
            },
            selected: null,
            status: [
                {value: null, text: 'Please select status'},
                {value: 'Active', text: 'Active'},
                {value: 'Inactive', text: 'Inactive'}
            ],
        }
    },
    methods: {
        getMBankingInfo(ispId) {
            this.$http.get(process.env.VUE_APP_BASEURL + MOBILE_BAKING_INFO+'?isp_id='+ispId)
                .then(res => {
                    if (res.data.data!=null){
                        this.mbakingData = res.data.data
                    }
                }).catch(error => {
                console.log(error)
            })
        },
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    useJwt.updateIspMobileBankingInfo({
                        isp_id: this.getIspId(),
                        bkash_account_number: this.mbakingData.bkash.account_number,
                        bkash_account_type: this.mbakingData.bkash.account_type,
                        bkash_account_status: this.mbakingData.bkash.status,
                        rocket_account_number: this.mbakingData.rocket.account_number,
                        rocket_account_type: this.mbakingData.rocket.account_type,
                        rocket_account_status: this.mbakingData.rocket.status,
                        nagad_account_number: this.mbakingData.nagad.account_number,
                        nagad_account_type: this.mbakingData.nagad.account_type,
                        nagad_account_status: this.mbakingData.nagad.status,
                        upay_account_number: this.mbakingData.nagad.account_number,
                        upay_account_type: this.mbakingData.upay.account_type,
                        upay_account_status: this.mbakingData.upay.status,
                    }).then(response => {
                        if (checkRes(response.data.code)) {
                            this.$router.push({ name: 'mobile-banking-payment-setting' })
                                .catch(error => {
                                    this.toastMessage('success','Mobile Banking Info',response)
                                })
                        }else {
                            this.$router.push({ name: 'mobile-banking-payment-setting' })
                                .catch(error => {
                                    this.toastMessage('warning','Mobile Banking Info',response)
                                })
                        }
                    }).catch(error => {
                        console.log(error)
                        this.toastMessage('danger','Mobile Banking Info', error)
                    })
                }
            })
        },
        getIspId() {
      return $("#ispListId option:selected").val();  
    }
    },
    mounted() { 
        this.isp_id = $("#ispListId option:selected").val(); 
        this.getMBankingInfo(this.isp_id)
        let instance = this; 
        $("#ispListId").change(function () { 
          this.isp_id=  $(this).children("option:selected").val();
          instance.getMBankingInfo(this.isp_id)
        });
    }
}
</script>

<style>

</style>
